<template>
  <v-container class="mt-10" justify="center" align="center">
    <v-row justify="center" align="center">
      <v-card>
        <v-card-title>
          <span class="text-h5">Pilih Customer</span>
        </v-card-title>
        <v-card-text>
          <v-dialog v-model="dialogDownloadKwt" width="450px">
            <v-card>
              <v-card-title class="justify-center"> Pilih Format File yang akan di Download</v-card-title>
              <v-card-text class="mt-5 text-center" text>
                <v-btn color="primary" class="mx-5" @click="downloadKwitansi('pdf')">Pdf</v-btn>
                <v-btn color="primary" class="mx-5" @click="downloadKwitansi('csv')">Csv</v-btn>
              </v-card-text>
            </v-card>
          </v-dialog>
          <v-text-field v-model="search" append-icon="mdi-magnify" label="Search" single-line hide-details></v-text-field>
          <v-divider></v-divider>
          <v-data-table :headers="headers" :items="customerData" :search="search" :loading="loading"
            loading-text="Loading... Please wait">
            <template v-slot:[`item.actions`]="{ item }">
              <v-btn color="primary" dark @click="pilihCustomer(item)" class="mr-2">
                Pilih
              </v-btn>
              <v-btn color="primary" dark @click="showDialogDownload(item)" v-if="item.COUNT_KWT !== 0">
                Download Kwt
              </v-btn>
              <v-btn v-else disabled>
                Download Kwt
              </v-btn>
              <!-- <v-btn color="primary" dark @click="cetakCsv(item)" v-if="item.COUNT_KWT !== 0">
                Download Kwt
              </v-btn>
              <v-btn v-else disabled>
                Download Kwt
              </v-btn> -->
            </template>
            <!-- <template v-slot:[`item.COUNT_KWT`]="{ item }">
              <a @click="cetakCsv(item)" v-if="item.COUNT_KWT !== 0" class="font-weight-bold">
                {{ item.COUNT_KWT }}
              </a>
              <div v-else class="font-weight-bold">
                {{ item.COUNT_KWT }}
              </div>
            </template> -->
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { mapGetters } from 'vuex';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
export default {
  data() {
    return {
      loading: true,
      dialog: true,
      search: '',
      headers: [
        { text: 'Customer Number', value: 'customer_number', align: 'center' },
        { text: 'Customer Name', value: 'customer_name', align: 'center' },
        { text: 'Npwp', value: 'npwp', align: 'center' },
        { text: 'Jumlah Kwitansi', value: 'COUNT_KWT', align: 'center' },
        { text: 'Actions', value: 'actions', sortable: false, align: 'center' }
      ],
      customerData: [],
      dialogDownloadKwt: false,
      itemData: {}
    }
  },
  methods: {
    pilihCustomer(item) {
      this.$session.remove('customer_id');
      this.$session.set('customer_id', item.customer_id);
      this.$session.set('customer_number', item.customer_number);
      this.$session.set('flag_bupot', item.flag_bupot);
      this.$root.$emit('eventing2');
      this.$router.push('/trxpage');
    },
    downloadKwitansi(tipe) {
      if (tipe === 'csv') {
        // const item = this.itemData;
        // console.log(item);
        axios({
          method: 'post',
          url: '/getDataCsvListKwitansiPerCustomer',
          data: {
            customer_number: this.itemData.customer_number,
            customer_name: this.itemData.customer_name,
            customer_id: this.itemData.customer_id
          },
          responseType: "blob"
        })
          .then((result) => {
            const contentDisposition = result.headers['content-disposition'];

            var fileName = contentDisposition.split(";");
            fileName = fileName[1].replace(' filename=', '');
            // console.log(fileName)
            const url = window.URL.createObjectURL(new Blob([result.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            this.loadingDownload = false;
            // const downloadData = window.URL.createObjectURL(new Blob([res.data]));
            // const downloadLink = document.createElement("a");
            // // const getType = downloadData.type;
            // // const getTypeSplit = getType.split("/");
            // downloadLink.href = URL.createObjectURL(new Blob([downloadData]));
            // // downloadLink.setAttribute("download", `file.${getTypeSplit[1]}`);
            // downloadLink.setAttribute("download", `Test.pdf`);
            // downloadLink.style.display = "none";
            // document.body.appendChild(downloadLink);
            // downloadLink.click();
            // document.body.removeChild(downloadLink);
          })
      } else {
        // const item = this.itemData;
        // console.log(item);
        axios({
          method: 'post',
          url: '/getDataKwitansi',
          data: {
            customer_number: this.itemData.customer_number,
            customer_name: this.itemData.customer_name,
            customer_id: this.itemData.customer_id
          }
        })
          .then((result) => {
            // console.log(result.data);
            var data_row = result.data.data;
            var dataRow = [];
            var nomor = 1;
            data_row.forEach(element => {
              var bodyRow = [];
              bodyRow.push({
                content: nomor,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: element.customer_number,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: element.customer_name,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: element.kwt_number,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: element.kwt_date,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: element.kwt_type,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: this.formatCurrency(element.dpp_amount),
                styles: { valign: 'middle', halign: 'right' }
              })
              bodyRow.push({
                content: this.formatCurrency(element.ppn_amount),
                styles: { valign: 'middle', halign: 'right' }
              })
              bodyRow.push({
                content: element.JENIS_PPH,
                styles: { valign: 'middle', halign: 'center' }
              })
              bodyRow.push({
                content: this.formatCurrency(element.pph_amount),
                styles: { valign: 'middle', halign: 'right' }
              })
              bodyRow.push({
                content: this.formatCurrency(element.TARIF),
                styles: { valign: 'middle', halign: 'right' }
              })
              bodyRow.push({
                content: this.formatCurrency(element.UMUR_KWT),
                styles: { valign: 'middle', halign: 'right' }
              })
              dataRow.push(bodyRow);
              // grand_total_dpp = grand_total_dpp + element.DPP_AMOUNT;
              // grand_total_pph = grand_total_pph + parseInt(element.PPH_AMOUNT);
              nomor++;
            });
            var now = new Date();
            var month = now.getMonth() + 1;
            var day = now.getDate();
            var hour = now.getHours();
            var minute = now.getMinutes();
            var second = now.getSeconds();
            if (month.toString().length == 1) {
              month = '0' + month;
            }
            if (day.toString().length == 1) {
              day = '0' + day;
            }
            if (hour.toString().length == 1) {
              hour = '0' + hour;
            }
            if (minute.toString().length == 1) {
              minute = '0' + minute;
            }
            if (second.toString().length == 1) {
              second = '0' + second;
            }
            var dateTime = now.toLocaleDateString('en-GB', {
              day: 'numeric', month: 'short', year: 'numeric'
            }).replace(/ /g, '-');
            var getWaktu = hour + ':' + minute + ':' + second;

            // var doc = new jsPDF();
            var doc = new jsPDF('l', 'mm', [297, 210]);
            doc.setFont("helvetica", "italic");
            // doc.addImage(imgLogo, 'PNG', 5, 5, 50, 20)
            doc.setFontSize(7);
            doc.text("Taxation HO Igr.", 10, 10);
            doc.text("Tgl. Cetak:" + dateTime, 265, 10);
            doc.text("Pkl. Cetak:" + getWaktu, 265, 13);
            doc.text("User Id:" + this.user.username, 265, 16);
            doc.getFontList();
            doc.setFont("helvetica", "bold");
            doc.setFontSize(12);
            doc.text("Listing Kwitansi Outstanding " + this.itemData.customer_name, 155, 22, { baseline: 'middle', align: 'center', fontStyle: 'bold' });
            doc.setFontSize(8);

            doc.autoTable({
              styles: {
                fontSize: 10
              },
              columnStyles: {
                0: { cellWidth: 8, fontSize: 8 },
                1: { cellWidth: 20, fontStyle: 'bold', fontSize: 8, textColor: 'black' },
                2: { halign: 'center', cellWidth: 55, fontSize: 8, textColor: 'black' },
                3: { cellWidth: 20, fontSize: 8, halign: 'right', textColor: 'black' },
                4: { cellWidth: 25, fontSize: 8, halign: 'right', textColor: 'black' },
                5: { cellWidth: 30, fontSize: 8, halign: 'right', textColor: 'black' },
                6: { cellWidth: 25, fontSize: 8, halign: 'right', textColor: 'black' },
                7: { halign: 'center', cellWidth: 23, fontSize: 8, textColor: 'black' },
                8: { halign: 'center', fontSize: 8, cellWidth: 23, textColor: 'black' },
                9: { halign: 'right', cellWidth: 20, fontSize: 8, textColor: 'black' },
                10: { halign: 'right', cellWidth: 20, fontSize: 8, textColor: 'black' },
                11: { halign: 'right', cellWidth: 20, fontSize: 8, textColor: 'black' },
              },
              headStyles: {
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                textColor: 'black'
              },
              theme: 'grid',
              head: [[
                { content: 'No ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Customer Number', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Customer Name ', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Nomor Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Tanggal Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Jenis Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Dpp Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Ppn Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Jenis Pph', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Pph Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Tarif Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
                { content: 'Umur Kwitansi', styles: { halign: 'center', fillColor: [255, 255, 255], textColor: 'black' } },
              ]],
              bodyStyles: { lineWidth: 0.5, lineColor: [0, 0, 0], fillColor: [255, 255, 255] },
              startY: 35,
              margin: { top: 5, left: 3, right: 1 },
              body: dataRow,
              footStyles: {
                lineWidth: 0.5,
                lineColor: [0, 0, 0],
                fillColor: [255, 255, 255],
                textColor: 'black'
              },
              rowPageBreak: 'auto'
            })
            window.open(doc.output('bloburl'))
          })
      }

    },
    showDialogDownload(item) {
      this.dialogDownloadKwt = true;
      this.itemData = item;
    },
    formatCurrency(value) {
      var formatter = new Intl.NumberFormat('en-US', {
      });

      return formatter.format(value); /* $2,500.00 */
    },
  },
  computed: {
    ...mapGetters({
      user: 'user',
    })
  },
  mounted() {
    // if (localStorage.getItem('reloaded')) {
    //     // The page was just reloaded. Clear the value from local storage
    //     // so that it will reload the next time this page is visited.
    //     localStorage.removeItem('reloaded');
    // } else {
    //     // Set a flag so that we know not to reload the page twice.
    //     localStorage.setItem('reloaded', '1');
    //     location.reload();
    // }
  },
  created() {
    axios({
      method: 'post',
      url: '/v1/getCustomerperuser',
      data: {
        user_id: this.user.id,
      },
    })
      .then(response => {
        this.customerData = response.data.data;
        this.loading = false;
        // window.location.reload();
      })
      .catch(error => {
        console.log(error.response)
      })
  }

}
</script>
